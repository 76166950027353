import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'

const cache = new InMemoryCache()

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        '[GraphQL error]: Message: ',
        message,
        ', Location: ',
        locations,
        ', Path: ',
        path
      )
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export default () => {
  const token = localStorage.getItem('TOKEN_KEY')

  const apolloUploadLink = createUploadLink({
    uri: '/api/graphql',
    headers: {
      Authorization: !!token ? `Bearer ${token}` : ''
    }
  })

  return new ApolloClient({
    cache,
    connectToDevTools: true,
    link: ApolloLink.from([logErrors, apolloUploadLink])
  })
}
