// Action Types
export const types = {};

const initalState = {};

// Reducer
export default function reducer(state = initalState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

// Action Creators
// ..

export const actions = {};
